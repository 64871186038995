import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

  /* 重定向 */
  {
    path: '/',
    redirect: '/home'
  },
  /* 首页 */
  {
    path: "/home",
    component: () => import('@/views/home'),

  },
]

const router = new VueRouter({

  routes
})

export default router
